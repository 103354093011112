<template>
  <div class="section-models-posts" :class="{ loading: loading }" v-bind="initData" :data-hasmore="morepage">
    <div class="section-models-posts-wrap" v-if="user">
      <div class="section-models-posts-header" v-if="setHeaderBasedOnFilter(filter, 'registerHeader')">
        <div class="section-models-posts-header-content is-four">
          <div class="wrp-btn" v-if="user.roles.includes('super')">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'new' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=new') }">
              Nuevos ({{ statistics["new"] }})
            </router-link>
          </div>
          <div class="wrp-btn">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'approved' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=approved') }">
              Aprobados ({{ statistics["approved"] }})
            </router-link>

            <div :class="`wrp-btn-ctx-menu ${user.roles.includes('coord') ? 'coord' : ''}`">
              <ModelsFilterInterviews />
            </div>
          </div>
          <div class="wrp-btn" v-if="someRole(['super', 'coord'])">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'noassistence' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=noassistence') }">
              No asistió ({{ statistics["noassistence"] }})
            </router-link>
          </div>
          <div class="wrp-btn" v-if="someRole(['super', 'coord'])">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'pending' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=pending') }">
              Pendientes ({{ statistics["pending"] }})
            </router-link>
            <div :class="`wrp-btn-ctx-menu ${user.roles.includes('coord') ? 'coord' : ''}`">
              <ModelsFilterInterviews />
            </div>
          </div>

          <div class="wrp-btn" v-if="user.roles.includes('super')">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'disapproved' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=disapproved') }">
              No Aprobados ({{ statistics["disapproved"] }})
            </router-link>
          </div>
        </div>
      </div>
      <div class="section-models-posts-header" v-else-if="setHeaderBasedOnFilter(filter, 'selectedHeader')">
        <div class="section-models-posts-header-content">
          <div class="wrp-btn">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'selected' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=selected') }">
              Seleccionadas ({{ statistics["selected"] }})
            </router-link>
          </div>
          <div class="wrp-btn">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'noselected' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=noselected') }">
              No seleccionadas ({{ statistics["noselected"] }})
            </router-link>
          </div>
        </div>
      </div>
      <div class="section-models-posts-header" v-else-if="setHeaderBasedOnFilter(filter, 'admittedHeader')">
        <div class="section-models-posts-header-content">
          <div class="wrp-btn">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'admitted' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=admitted') }">
              Admitidas ({{ statistics["admitted"] }})
            </router-link>
          </div>
          <div class="wrp-btn">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'waiting' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=waiting') }">
              En espera ({{ statistics["waiting"] }})
            </router-link>
          </div>
          <div class="wrp-btn" v-if="user.roles.includes('super')">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'noadmitted' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=noadmitted') }">
              No admitidas ({{ statistics["noadmitted"] }})
            </router-link>
          </div>
        </div>
      </div>
      <div class="section-models-posts-header" v-else-if="setHeaderBasedOnFilter(filter, 'hiredHeader')">
        <div class="section-models-posts-header-content">
          <div class="wrp-btn">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'hired' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=hired') }">
              Contratadas ({{ statistics["hired"] }})
            </router-link>
          </div>
          <div class="wrp-btn">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'deserter' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=deserter') }">
              Desertoras ({{ statistics["deserter"] }})
            </router-link>
          </div>
          <div class="wrp-btn">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'waiver' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=waiver') }">
              Renuncias ({{ statistics["waiver"] }})
            </router-link>
          </div>
          <div class="wrp-btn">
            <router-link
              @click.native="filterInfo('')"
              :to="{ query: Object.assign({}, this.$route.query, { filter: 'endContract' }), hash: '#app' }"
              :class="{ 'router-link-exact-active': linkInclude('?filter=endContract') }">
              Cancelación de contrato ({{ statistics["endContract"] }})
            </router-link>
          </div>
        </div>
      </div>
      <ModelsFilter :total="statistics[filter] >= 0 ? statistics[filter] : total" ref="ModelsFilter" :filter="filter" />
      <ModelsFilterInfo ref="filterInfo" :total="total_list" />
      <ModelsGraphycs v-if="mode === 'graphics'" :total="total" :data="this.data" />
      <div v-else>
        <div class="section-models-posts-wrap" :key="elKey" v-bind="initList">
          <div class="section-models-posts-main">
            <div class="section-models-posts-main-content" v-if="posts" id="section-cards">
              <div
                v-for="(post, index) in posts"
                :key="index"
                :id="`model-${post._id}`"
                :class="`list-item ${yearsDiff(post.birthDayDate, Date.now()) < 18 ? 'younger' : ''}`"
                :record="post._id"
                :gender="post.gender"
                :priority="post.priority"
                :satelite="post.satelite"
                v-if="compareDate(user, post)">
                <form :class="`list-item-content item-${index}`" :id="`form-item-${index}`" :data-index="index">
                  <div class="model-selected">
                    <span class="check tooltip" relative inline-b>
                      <input
                        class="mycheck"
                        name="isApproved"
                        type="checkbox"
                        :checked="isChecked.includes(post.adminStatus) ? true : false"
                        :events="noApprovable.includes(filter) ? 'false' : 'true'"
                        @click="slectModel(`.item-${index}`)" />
                      <span class="tooltiptext tool-auto">¿Aprobar?</span>
                    </span>
                    <input type="hidden" name="model" :value="post.email" />
                    <input type="hidden" name="cfilter" :value="filter" />
                    <button class="btn-history" type="button" v-if="post.history.length" @click="showHistory(post.history)">
                      <iconic name="history" />
                    </button>
                  </div>
                  <div class="model-info">
                    <div class="model-info-data">
                      <div class="info">
                        <div class="header-info">
                          <div class="name">
                            <strong>{{ post.name }}</strong>
                            <span v-html="`&nbsp;`"></span>
                            <span class="model-age">( Edad : {{ yearsDiff(post.birthDayDate, Date.now()) }} Años )</span>
                          </div>
                          <span>|</span>
                          <div class="city-info">
                            <div class="row">
                              <div class="city-label">
                                <b>{{ cityName(post.city) }}</b>
                                <b v-if="post.neighborhood">{{ ` - ${post.neighborhood}` }}</b>
                              </div>
                              <img class="section-models-posts__logo" src="../../assets/img/roa.png" alt="LogoRoa" v-if="post?.siteRegister === 'Roa'" />
                              <a dcolor v-if="filter === 'new' && user.roles.includes('super')" @click="citySelect(post._id)" cursor="pointer">
                                <i class="fa fa-pencil-square-o"></i>
                              </a>
                            </div>
                            <CitiesSelect :ref="`city${post._id}`" name="modelCity" :value="post.city" />
                          </div>
                        </div>
                        <div class="date">
                          <span class="sec-font">Fecha de registro: {{ dateFormat(post.date) }}</span>
                          <span class="sec-font" v-html="` Hora: ${timeAMPM(post.time)}`"></span>
                          <a class="view-reg dcolor" bold @click="viewModel(index)">Ver registro</a>
                        </div>
                        <div fsmall>
                          <a :href="`tel:${post.phone}`" class="phone-info" v-html="showPhoneInfo(post)"></a>
                          , email:
                          <a :href="`mailto:${post.email}`">{{ post.email }}</a>
                          , Contacto:
                          <span v-for="(ctmode, ctmodeidx) in post.contactMode" :key="ctmodeidx">
                            <b v-html="`${getContactLink(post, ctmode)} `"></b>
                          </span>
                        </div>
                        <div>
                          Ha sido modelo web :
                          {{ locale.words[post.hasWebcam] }}
                        </div>
                      </div>
                      <div class="pictures">
                        <div class="pictures-content">
                          <div class="picture pointer" v-for="(photo, i) in post.photos" :key="'photos-' + i">
                            <img
                              :alt="photo.name"
                              :src="photo.link"
                              @click="
                                $refs.myGallery.photoView({
                                  action: 'open',
                                  idx: i,
                                  photos: post.photos,
                                })
                              " />
                          </div>
                          <div class="picture video pointer" v-if="post.videoModel">
                            <video
                              class="pictures__video"
                              @click="
                                $refs.myWatch.videoView({
                                  action: 'open',
                                  link: post.videoModel,
                                })
                              ">
                              <track label="Español" kind="captions" srclang="en" />
                              <source :src="post.videoModel" />
                            </video>
                          </div>
                          <div class="picture video pointer" v-if="post.cameraTestVideo">
                            <a class="wrap-link" block>
                              <img
                                :alt="post.photos[0].name"
                                :src="post.photos[0].link"
                                @click="
                                  $refs.myWatch.videoView({
                                    action: 'open',
                                    link: post.cameraTestVideo,
                                  })
                                " />
                            </a>
                          </div>
                          <div class="picture pointer" v-for="(photo, i) in post.multimedia.photos" :key="'multiPhoto-' + i">
                            <button type="button" class="deleteFile" @click="deleteMultimedia('photos', index, i, post)" v-if="permisionsByRole(filter, 'delete')">
                              <iconic name="trash" />
                            </button>
                            <img
                              :alt="photo.name"
                              :src="photo.link"
                              v-if="photo.link"
                              @click="
                                $refs.myGallery.photoView({
                                  action: 'open',
                                  idx: i,
                                  photos: post.multimedia.photos,
                                })
                              " />
                            <div class="loader" v-else></div>
                          </div>
                          <div v-for="(video, i) in post.multimedia.videos" :class="`picture video pointer`" :key="'multiVideo-' + i">
                            <a class="wrap-link" block>
                              <button type="button" class="deleteFile" @click="deleteMultimedia('videos', index, i, post)" v-if="permisionsByRole(filter, 'delete')">
                                <iconic name="trash" />
                              </button>
                              <img
                                :alt="post.photos[0].name"
                                :src="post.photos[0].link"
                                v-if="video.link"
                                @click="
                                  $refs.myWatch.videoView({
                                    action: 'open',
                                    link: video.link,
                                  })
                                " />
                              <div class="loader" v-else></div>
                            </a>
                          </div>
                          <label :class="`multimedia-${post._id} picture`" v-if="post.testCamera == 'true' && permisionsByRole(filter, 'add')">
                            <div class="tooltip relative uploadFile" w100>
                              <iconic name="plus" />
                              <input
                                type="file"
                                class="hidden"
                                id="inputMultimedia"
                                name="multimedia"
                                placeholder="Video"
                                @change="validateFile($event, index)"
                                w100
                                :accept="extensiones.photos.join(',') + ',' + extensiones.video.join(',')" />
                            </div>
                          </label>
                        </div>
                      </div>
                      <div class="model-rate" v-if="filter === 'selected'">
                        <div class="model-ratings" v-if="post.ratings.length > 0">
                          <div class="model-ratings-history">
                            <div role="label" bold>Calificaciónes ( {{ post.ratings.length }} )</div>
                            <table>
                              <tr class="row" v-for="(rating, ridx) in post.ratings" :key="ridx">
                                <td>{{ rating.userName }}</td>
                                <td class="rating">{{ rating.userRating }}</td>
                                <td>{{ dateFormat(rating.dateRating) }}</td>
                              </tr>
                            </table>
                          </div>
                          <div class="model-ratings-score">
                            <div role="label" bold>Puntaje</div>
                            <table>
                              <tr class="row" v-for="(score, sidx) in post.ratingsScore" :key="sidx">
                                <td>{{ score.userRating }}</td>
                                <td>{{ score.count }}</td>
                                <td class="rating">{{ score.average.toFixed(0) }} %</td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div class="model-rate-myrating" v-if="!post.iRated">
                          <div role="label">Mi calificación</div>
                          <div role="select-radio">
                            <div role="option">
                              <input type="radio" :id="`noAdmitted-${index}`" name="myRating" value="noadmitted" />
                              <label :for="`noAdmitted-${index}`">No admitida</label>
                            </div>
                            <div role="option">
                              <input type="radio" :id="`onWait-${index}`" name="myRating" value="waiting" />
                              <label :for="`onWait-${index}`">En espera</label>
                            </div>
                            <div role="option">
                              <input type="radio" :id="`isAdmitted-${index}`" name="myRating" value="admitted" />
                              <label :for="`isAdmitted-${index}`">Admitida</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="model-assessment">
                        <div v-if="filter === 'selected' && post.iRated && user.roles.includes('super')">
                          <StarsRatingsInput :index="index" />
                        </div>
                        <div v-else-if="filter === 'admitted'">
                          <StarsRatingsInput :current="post.selectRating" :readonly="true" :index="index" />
                        </div>
                      </div>
                    </div>
                    <div class="test-info" v-if="filter === 'approved' || filter === 'pending'" :key="resetKey">
                      <div class="row">
                        <div w100>
                          <div class="datetime-input-block">
                            <div>Fecha y hora de la entrevista</div>
                            <input type="datetime-local" name="interviewDate" class="m-bottom" :value="toInputDateTime(post.interviewDate)" :min="getCurrentDateTime()" />
                          </div>
                          <div v-html="interviewMessage(post, index)"></div>
                        </div>
                      </div>
                      <div class="row">
                        <span class="check">
                          <input
                            class="mycheck"
                            name="testCamera"
                            type="checkbox"
                            :checked="post.testCamera == 'true' ? true : false"
                            @change="testCamera($event, index)"
                            :disabled="post.multimedia.photos.length || post.multimedia.videos.length || false" />
                          <input type="hidden" name="testCamera" :value="post.testCamera == 'true' ? 'on' : 'off'" />
                        </span>
                        <span>Prueba de cámara</span>
                      </div>
                      <div class="row interviewRow">
                        <div class="interviewRowField">
                          <div class="interviewRowField-left">
                            <span class="check">
                              <input
                                class="mycheck"
                                name="isSelected"
                                type="checkbox"
                                @change="onLinkToChange($event, post, post.isSelected === 'true' ? false : true, index)"
                                :checked="post.isSelected === 'true' || post.availableInterview ? true : false" />
                            </span>
                            <span class="labeInterview">Entrevista</span>
                            <a class="linkTo link-i" @click.prevent="goInterview(post)">
                              <i class="fa fa-link"></i>
                            </a>
                          </div>
                          <div class="interviewRowField-right">
                            <ToggleSwitch
                              class="toggleLinkTo"
                              :label="post.availableInterview ? 'Link activo' : 'Link inactivo'"
                              :checked="post.availableInterview"
                              @change.native="onLinkToChange($event, post, !post.availableInterview, index)" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <span class="check">
                          <input class="mycheck" name="isContacted" type="checkbox" :checked="post.isContacted === 'on' ? true : false" />
                        </span>
                        <span>Contactada</span>
                      </div>
                      <div class="row">
                        <ModelComment :post="post" @newComment="newComment($event, index)">
                          <TextareaRich placeholder="Agregar comentario" name="commentbox" />
                        </ModelComment>
                      </div>
                      <div class="row">
                        <div class="row-comments">
                          <ModelComments :comments="post.comments" />
                        </div>
                      </div>
                      <div class="row">
                        <ToggleSwitch name="endProcess" label="Proceso completo" style="margin-top: 5px" />
                      </div>
                    </div>
                    <div class="test-info" v-else-if="['new', 'disapproved', 'selected', 'noselected', 'waiting', 'hired', 'waiver', 'endContract'].includes(filter)">
                      <div class="row">
                        <Reasons :post="post" v-if="['waiver', 'endContract'].includes(filter)" @success="editType" />
                      </div>
                      <div class="row" v-if="['hired'].includes(filter)">
                        <div w100>
                          <div>Fecha de ingreso</div>
                          <input type="date" name="startDate" class="m-bottom" :value="toInputDate(post.startDate)" />
                        </div>
                      </div>
                      <div class="row">
                        <ModelComment :post="post" @newComment="newComment($event, index)">
                          <TextareaRich placeholder="Agregar comentario" name="commentbox" />
                        </ModelComment>
                      </div>
                      <div class="row">
                        <div class="row-comments">
                          <ModelComments :comments="post.comments" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="stream-info" v-if="['hired'].includes(filter)" w100>
                          <ModelsPlatformForm :model="post" />
                          <ModelsLocationForm :model="post" />
                          <div :class="['row end-process', post.idUserCollection && 'hiddenToogle']">
                            <ToggleSwitch name="endProcess" label="Proceso completo" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="test-info" v-else-if="filter === 'admitted' || filter === 'deserter'">
                      <div class="row">
                        <div w100>
                          <div>Fecha de ingreso</div>
                          <input type="date" name="startDate" class="m-bottom" :value="toInputDate(post.startDate)" />
                        </div>
                      </div>
                      <div class="row">
                        <span class="check">
                          <input class="mycheck" name="signContract" type="checkbox" :checked="post.signContract === 'on' ? true : false" />
                        </span>
                        <span>Firma contrato</span>
                      </div>
                      <div class="row photo-section flex-column">
                        <div class="photo-section-toggle flex-between">
                          <div class="display-flex">
                            <span class="check">
                              <input class="mycheck" name="photoSection" type="checkbox" :checked="post.photoSection === 'on' ? true : false" />
                            </span>
                            <span>Sesión fotográfica</span>
                          </div>
                          <div class="display-flex">
                            <a button flex-center @click="$refs[`ps-${post._id}`][0].toggle()">
                              <span v-if="post.photoSesionDate">{{ toInputDate(post.photoSesionDate) }}</span>
                              <span v-else>Programar</span>
                              <span :class="`chevron flex-center`" :style="`${PhotoSectionForm === post._id ? 'transform:rotate(180deg)' : ''}`">
                                <iconic name="chevronDown" />
                              </span>
                            </a>
                          </div>
                        </div>
                        <div class="photo-section-content">
                          <Collapse :ref="`ps-${post._id}`" header="hidden" @open="PhotoSectionForm = post._id" @close="PhotoSectionForm = null">
                            <ModelsPhotoSectionForm :key="post._id" :model="post" />
                          </Collapse>
                        </div>
                      </div>
                      <div class="row">
                        <ModelComment :post="post" @newComment="newComment($event, index)">
                          <TextareaRich placeholder="Agregar comentario" />
                        </ModelComment>
                      </div>
                      <div class="row">
                        <div class="row-comments">
                          <ModelComments :comments="post.comments" />
                        </div>
                      </div>
                      <div class="row">
                        <ToggleSwitch name="endProcess" label="Proceso completo" style="margin-top: 5px" />
                      </div>
                    </div>
                    <div class="test-info" v-else-if="filter === 'noassistence'">
                      <div class="row">
                        <div w100 class="container-new-interview">
                          <div class="box-reprogramar" message danger small margin-b>
                            <span>No se presentó a la entrevista</span>
                            <iconic name="warning" />
                          </div>
                          <div class="datetime-input-block">
                            <div>Fecha y hora de la entrevista</div>
                            <input
                              type="datetime-local"
                              name="interviewDate"
                              class="m-bottom"
                              :value="toInputDateTime(post.interviewDate)"
                              :min="getCurrentDateTime()"
                              @input="post.keysConfigs.dateInterviewChanged = true" />
                          </div>
                          <a
                            v-if="post.keysConfigs.dateInterviewChanged"
                            class="primary-button btn-action newInterview"
                            @click="dataSend(`form-item-${index}`, { append: { action: 'super-select' }, post })">
                            <span>Reprogramar entrevista</span>
                          </a>
                          <a
                            :class="`primary-button pc-round btn-action`"
                            @click="
                              dataNoAssistence(
                                `form-item-${index}`,
                                { append: { action: 'super-select' }, post },
                                {
                                  nombre: 'No seleccionadas',
                                  valor: 'noselected',
                                }
                              )
                            ">
                            <span>No seleccionada</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="test-info" v-else>
                      <div class="test-info-content">
                        <div class="test-info-content-info" v-if="filter === 'all'">
                          <ModelsInfoBlock :post="post" :locale="locale" />
                        </div>
                        <Reasons :post="post" :hasEdit="false" v-if="['waiver', 'endContract'].includes($route.query.status)" />
                        <div class="test-info-content-details" v-if="post.comments.length">
                          <div bold>Comentarios</div>
                          <div class="row-comments">
                            <ModelComments :comments="post.comments" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="info-message" v-if="filter !== 'noassistence'">
                      <div v-if="post.youMessage">
                        <div bold>Mensaje</div>
                        <div>{{ post.youMessage }}</div>
                      </div>
                      <div class="model-options">
                        <a
                          v-if="['pending', 'noassistence', 'new', 'approved', 'disapproved'].includes(filter)"
                          class="primary-button pc-round btn-action"
                          @click="
                            filter == 'pending'
                              ? dataWaitingSelect(
                                  `form-item-${index}`,
                                  { append: { action: 'super-select' }, post },
                                  {
                                    positiveOption: {
                                      nombre: 'Aprobado',
                                      valor: 'approved',
                                    },
                                    negativeOption: {
                                      nombre: 'No seleccionadas',
                                      valor: 'noselected',
                                    },
                                  }
                                )
                              : dataSubmitConfirm(`form-item-${index}`, {
                                  post,
                                })
                          ">
                          <span>{{ filter == "pending" || filter == "noassistence" ? "Actualizar" : "Enviar" }}</span>
                        </a>
                        <div class="row" v-if="filter == 'approved' && someRole(['super', 'coord'])">
                          <a
                            class="btn-action"
                            @click="
                              dataPending(`form-item-${index}`, {
                                append: { action: 'super-select' },
                                post,
                              })
                            ">
                            <span class="link-i" dcolor>
                              <span>Pendiente</span>
                              <iconic name="shareSquare" />
                            </span>
                          </a>
                        </div>
                        <a v-else-if="filter == 'selected' && !post.iRated" class="primary-button pc-round btn-action" @click="dataSubmitConfirm(`form-item-${index}`, { post })">
                          <span>Calificar</span>
                        </a>
                        <a
                          v-else-if="filter == 'selected' && user.roles.includes('super')"
                          class="primary-button pc-round btn-action"
                          @click="
                            dataSubmitConfirm(`form-item-${index}`, {
                              append: { action: 'super-select' },
                              post,
                            })
                          ">
                          <span>Enviar</span>
                        </a>
                        <a
                          v-else-if="filter == 'noselected' && someRole(['super', 'coord'])"
                          class="primary-button pc-round btn-action"
                          @click="
                            dataNoSelect(`form-item-${index}`, {
                              append: { action: 'super-select' },
                              post,
                            })
                          ">
                          <span>Actualizar</span>
                        </a>
                        <a
                          v-else-if="filter == 'waiting' && user.roles.includes('super')"
                          class="primary-button pc-round btn-action"
                          @click="
                            dataWaitingSelect(
                              `form-item-${index}`,
                              {
                                append: { action: 'super-select' },
                                post,
                              },
                              {
                                positiveOption: {
                                  nombre: 'Admitir',
                                  valor: 'admitted',
                                },
                                negativeOption: {
                                  nombre: 'No Admitir',
                                  valor: 'noadmitted',
                                },
                              }
                            )
                          ">
                          <span>Actualizar</span>
                        </a>
                        <a
                          v-else-if="filter == 'admitted' && (user.roles.includes('super') || user.roles.includes('coord'))"
                          class="primary-button pc-round btn-action"
                          @click="
                            dataAdmittedConfirm(`form-item-${index}`, {
                              append: { action: 'admitted-select' },
                              post,
                            })
                          ">
                          <span>Enviar</span>
                        </a>
                        <a
                          v-else-if="filter == 'hired' && (user.roles.includes('super') || user.roles.includes('coord'))"
                          class="primary-button pc-round btn-action"
                          @click="
                            dataHiredConfirm(`form-item-${index}`, {
                              append: { _id: post._id },
                              post,
                            })
                          ">
                          <span>Enviar</span>
                        </a>
                        <a
                          v-else-if="filter == 'deserter' && user.roles.includes('super')"
                          class="primary-button pc-round btn-action"
                          @click="
                            dataAdmittedConfirm(`form-item-${index}`, {
                              append: { action: 'admitted-select' },
                              post,
                            })
                          ">
                          <span>Enviar</span>
                        </a>
                        <a
                          v-else-if="filter == 'noadmitted' && user.roles.includes('super')"
                          class="primary-button pc-round btn-action"
                          @click="
                            dataWaitingSelect(
                              `form-item-${index}`,
                              {
                                append: { action: 'noadmitted-select' },
                                post,
                              },
                              {
                                positiveOption: {
                                  nombre: 'Admitir',
                                  valor: 'admitted',
                                },
                                negativeOption: {
                                  nombre: 'En espera',
                                  valor: 'waiting',
                                },
                              }
                            )
                          ">
                          <span>Actualizar</span>
                        </a>
                      </div>
                      <div class="row sendIcon">
                        <a
                          class="pc-round btn-action"
                          v-if="filter == 'admitted' && user.roles.includes('super')"
                          @click="
                            dataAdmittedConfirm(`form-item-${index}`, {
                              append: {
                                action: 'admitted-toWait',
                                waiting: 'waiting',
                              },
                              post,
                            })
                          ">
                          <span class="sendIcon__span">
                            <p class="sendIcon__text">Mover (En Espera)</p>
                            <i class="fa fa-paper-plane sendIcon__icon"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                    <div class="info-message" v-else>
                      <div class="row">
                        <ModelComment :post="post" @newComment="newComment($event, index)">
                          <TextareaRich placeholder="Agregar comentario" name="commentbox" />
                        </ModelComment>
                      </div>
                      <div class="test-info-content-details" v-if="post.comments.length">
                        <div bold>Comentarios</div>
                        <div class="row-comments">
                          <ModelComments :comments="post.comments" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="load-more-block relative" :class="{ loading: loading }" v-if="morepage">
          <div class="load-more-block-content" v-if="!loading">
            <button class="click-on-bottom" @click="getPosts((cpage += 1))">Ver mas</button>
          </div>
          <div class="onScrollLoading preventStayOnBottom" v-else-if="loading"></div>
        </div>
      </div>
    </div>
    <GalleryVisor ref="myGallery"></GalleryVisor>
    <ModelsRecord ref="myRecord" @updatedModel="updatedModel"></ModelsRecord>
    <Watch ref="myWatch"></Watch>
    <ModalHired v-if="isShowModalHired" :modelName="dataHiredModal.options.post.name" @submit="onContract" @cancel="isShowModalHired = false" />
    <router-view></router-view>
  </div>
</template>

<script>
  import Vue from "vue";
  import api from "../../api";
  import global from "../../assets/js/global";
  import store from "../../store/index.js";
  import GalleryVisor from "../web/GalleryVisor";
  import ModelsInfoBlock from "./ModelsInfoBlock";
  import ModelsRecord from "./ModelsRecord";
  import ModelsFilter from "./ModelsFilter";
  import ModelsFilterInfo from "./ModelsFilterInfo";
  import ModelsGraphycs from "./ModelsGraphycs";
  import Watch from "../widgets/VideoModal.vue";
  import StarsRatingsInput from "../widgets/StarsRatingsInput";
  import ToggleSwitch from "../inputs/ToggleSwitch";
  import CitiesSelect from "../inputs/CitiesSelect";
  import Swal from "sweetalert2";
  import TextareaRich from "../widgets/TextareaRich.vue";
  import ModelComment from "./ModelComment.vue";
  import ModelComments from "./ModelComments.vue";
  import ModelsFilterInterviews from "./ModelsFilterInterviews.vue";
  import globals from "../../assets/js/global";
  import Socket from "@/common/socket/socketBackend";
  export default {
    components: {
      GalleryVisor,
      ModelsRecord,
      ModelsInfoBlock,
      ModelsFilter,
      ModelsFilterInfo,
      StarsRatingsInput,
      ToggleSwitch,
      Watch,

      CitiesSelect,
      ModelsGraphycs,
      TextareaRich,
      ModelComment,
      ModelComments,
      ModelsFilterInterviews,
      ModalHired: () => import("@/components/modals/ModalHireds.vue"),
      Reasons: () => import("@/components/admin/ModelsReason.vue"),
      ModelsPhotoSectionForm: () => import("@/components/admin/ModelsPhotoSectionForm.vue"),
      ModelsPlatformForm: () => import("@/components/admin/ModelsPlatformForm.vue"),
      ModelsLocationForm: () => import("@/components/admin/ModelsOfficeSelect.vue"),
      Collapse: () => import("@/components/widgets/Collapse.vue"),
    },
    data: () => {
      return {
        posts: null,
        loading: null,
        cpage: 1,
        morepage: false,
        filter: "",
        mode: "graphics",
        extensiones: {
          video: [".mp4", ".mov", ".wmv", ".avi"],
          photos: [".webp", ".jpg", ".jpeg", ".png", ".gif"],
        },
        formChanged: false,
        elKey: 0,
        locale: null,
        total: null,
        total_list: null,
        statistics: {},
        isChecked: ["approved", "selected", "noselected", "admitted", "waiting", "noadmitted", "hired", "deserter", "pending", "noassistence"],
        noApprovable: ["selected", "noselected", "admitted", "waiting", "noadmitted", "hired", "deserter", "all"],
        isShowModalHired: false,
        dataHiredModal: {},
        PhotoSectionForm: null,
        PlatformForm: null,
        showPost: 0,
        roleUser: "",
        userName: "",
        countries: [],
        resetKey: 1,
        socket: null,
      };
    },
    props: {
      items: Array,
      data: Object,
      user: Object,
    },
    methods: {
      async initializeSocketBackend() {
        const socket = new Socket(this.user);
        const isConnected = await socket.connectToBackend();
        if (isConnected.connected) {
          this.socket = socket.getSocket();
          this.initSocketsEvents();
        }
      },
      initSocketsEvents() {
        this.socket.on("interviewFinished", (response) => {
          const { id } = response;
          this.interviewFinished(id);
        });
      },
      handleCheckedInterview(indexModelo, flag) {
        if (!this.posts[indexModelo].isInterview) {
          Vue.set(this.posts[indexModelo], "isSelected", `${flag}`);
          Vue.set(this.posts[indexModelo], "availableInterview", flag);
          document.querySelectorAll("[name=isSelected]")[indexModelo].checked = flag;
          document.querySelectorAll(".interviewRowField-right .toggle-checkbox")[indexModelo].checked = flag;
        } else {
          document.querySelectorAll("[name=isSelected]")[indexModelo].checked = true;
        }
      },
      /**
       * Funcion encargada de devolver la fecha para bloquear los dias anteriores en el input de fecha
       */
      getCurrentDateTime() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, "0");
        const day = String(now.getDate()).padStart(2, "0");
        const hour = String(now.getHours()).padStart(2, "0");
        const minute = String(now.getMinutes()).padStart(2, "0");
        return `${year}-${month}-${day}T${hour}:${minute}`;
      },

      showPhoneInfo: function (model) {
        let html = "";
        const { inputPostalCode = "" } = model;
        const hasPostalCode = inputPostalCode.trim();
        if (hasPostalCode) {
          const infoZipCode = this.countries.filter((country) => country.callingCodes?.includes(inputPostalCode))[0];
          html = `
            <span>Telefono: </span>
            <img src=${infoZipCode?.flag} alt=${infoZipCode?.name} title=${infoZipCode?.name} />
            <span>+${inputPostalCode}</span>
            <span>${model.phone}</span>
        `;
        } else {
          html = `
          <span>Telefono: </span>
          <span>${model.phone}</span>
        `;
        }
        return html;
      },

      /**
       * Funcion encargadad de mostrar
       * @param {*} filter
       */
      setHeaderBasedOnFilter(filter, nameHeader) {
        const headers = {
          registerHeader: ["new", "approved", "pending", "disapproved", "noassistence"],
          admittedHeader: ["admitted", "noadmitted", "waiting"],
          selectedHeader: ["selected", "noselected"],
          hiredHeader: ["hired", "deserter", "waiver", "endContract"],
        };
        return headers[nameHeader].includes(filter);
      },
      /**
       * Funcion encargada de permitir gestionar un registro a un tipo de usuario en un determinado filtro
       * @param {*} filter
       * @param {*} action
       */
      permisionsByRole: function (filter, action) {
        if (["coordinator", "superadmin"].includes(this.roleUser)) {
          // Permisos de los coordinadores
          const permisions = {
            delete: ["approved", "pending"],
            add: ["approved", "pending", "selected", "noselected"],
          };
          return this.roleUser != "superadmin" ? permisions[action].includes(filter) : true;
        }
        return false;
      },

      /**
       * Función auxiliar para agrupar los elementos del historial por fecha y mostrarlos
       * @param {*} history
       */
      showHistory: function (history) {
        const groupedHistory = this.groupByDate(history);

        // Crear una sección para cada grupo de elementos y agregarlos a una cadena de HTML
        let htmlHistory = "<div class='history__container'>";
        Object.entries(groupedHistory).forEach(([date, items]) => {
          const sectionHtml = this.createHistorySectionHtml(date, items);
          htmlHistory += sectionHtml;
        });
        htmlHistory += "</div>";

        Swal.fire({
          title: "Historial",
          html: htmlHistory,
          showCancelButton: false,
          confirmButtonText: "cerrar",
          confirmButtonColor: "#bd0909",
        });
      },

      /**
       * Función auxiliar para agrupar los elementos del historial por fecha
       * @param {*} history
       */
      groupByDate: function (history) {
        const grouped = {};

        // Se ordenan las fechas de mayor a menor
        history
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((item) => {
            const date = new Date(item.date).toDateString();
            if (!grouped[date]) {
              grouped[date] = [];
            }
            grouped[date].push(item);
          });
        return grouped;
      },

      /**
       * Función auxiliar para crear la sección de HTML para un grupo de elementos agrupados por fecha
       * @param {*} date
       * @param {*} items
       */
      createHistorySectionHtml: function (date, items) {
        let sectionHtml = `<div class="history__section"><p class="date__history">`;
        const today = new Date().toDateString();
        const yesterday = new Date(Date.now() - 86400000).toDateString();
        if (date === today) sectionHtml += "Hoy";
        else if (date === yesterday) sectionHtml += "Ayer";
        else {
          const formatter = new Intl.DateTimeFormat("es", { year: "numeric", month: "long", day: "numeric" });
          const fechaFormateada = formatter.format(new Date(date));
          sectionHtml += fechaFormateada;
        }

        sectionHtml += `</p>`;

        items.map((historyItem) => {
          const date = new Date(historyItem.date);
          sectionHtml += `
          <div class="history__item">
            <div class="action">
              <h1>${historyItem.user}</h1>
              <p class="${historyItem.action == "Agregó un archivo." ? "success" : "danger"}">
                <span>${historyItem.action}</span>
                </p>
            </div>
            <p class="history__date">
              ${date.getHours()}:${date.getMinutes() < 10 ? "0" : ""}${date.getMinutes()}
              ${date.getHours() < 12 ? "AM" : "PM"}
            </p>
          </div>
        `;
        });

        sectionHtml += `</div>`;
        return sectionHtml;
      },

      /**
       * Funcion encargada de eliminar un elemento multimedia de una modelo
       * @param {*} type
       * @param {*} indexModelo
       * @param {*} indexMultimedia
       * @param {*} model
       */
      deleteMultimedia: async function (type, indexModelo, indexMultimedia, model) {
        Swal.fire({
          title: "Eliminar",
          html: "Esta seguro que desea eliminar este elemento?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#2196f3",
          cancelButtonColor: "#bd0909",
          confirmButtonText: "Si, enviar",
          cancelButtonText: "No, cancelar",
          preConfirm: async () => {
            const decision = Swal.getPopup().querySelector(".last-contract-decision input:checked");
            if (!decision) {
              // guarda el elemento con el fin de eliminarlo tambien de AWS
              const fileToDelete = this.posts[indexModelo].multimedia[type].splice(indexMultimedia, 1);
              const _host = `${process.env.VUE_APP_API_HOST}admin/models/deleteMultimedia`;
              const _data = new FormData();
              const modelo = this.posts[indexModelo].multimedia;

              if (!(modelo.photos.length || modelo.videos.length) && ["approved", "pending"].includes(this.filter)) {
                this.posts[indexModelo].testCamera = "false";
              }
              model.history.push({
                date: new Date(),
                user: this.userName,
                action: "Eliminó un archivo.",
              });
              _data.append("multimedia", JSON.stringify(model.multimedia));
              _data.append("history", JSON.stringify(model.history));
              _data.append("fileToDelete", fileToDelete[0].link);
              _data.append("id", model._id);
              api.post(_host, _data);
            }
          },
        });
      },
      compareDate(user, post) {
        if (user.role === "superadmin" || user.role === "coordinator") {
          return true;
        }
        if (new Date(user.polices["see-from"].value) < new Date(post.date) && user.polices["see-from"].status) {
          return true;
        } else {
          return false;
        }
      },
      filterInfo: function (info) {
        this.$refs.filterInfo.info(info);
      },
      someRole: function (checkRole) {
        const myRoles = this.user.roles;
        return global.hasSome(checkRole, myRoles);
      },
      getdataLang: async function () {
        this.locale = await store.getters["localeLang"];
      },
      getRequest: function (page) {
        let currentQuery = this.$route.fullPath.substring(1);
        if (currentQuery && currentQuery.includes("#")) {
          currentQuery = currentQuery.split("#")[0];
        }
        let _host = `${process.env.VUE_APP_API_HOST}${currentQuery}&page=${page}`;
        return _host;
      },
      getPosts: async function (page) {
        const _this = this;
        this.loading = true;

        if (this.items) {
          this.posts = this.items;
        } else {
          page = page >= 1 ? page : 1;
          const _host = this.getRequest(page);

          const response = await api.get(`${_host}`);
          const dataSorted = response.data.models.map((modelo) => {
            modelo.multimedia = modelo?.multimedia ?? { photos: [], videos: [] };

            if (!modelo.hasOwnProperty("photos")) {
              modelo.photos = [];
            }
            if (!modelo.multimedia.videos.length) {
              modelo.multimedia.videos = [];
            }
            if (!modelo.multimedia.photos.length) {
              modelo.multimedia.photos = [];
            }
            modelo.history = modelo?.history ?? [];
            if (modelo.multimedia.photos.length || modelo.multimedia.videos.length) {
              modelo.testCamera = "true";
            } else {
              modelo.testCamera = "false";
            }
            return {
              ...modelo,
              keysConfigs: {
                dateInterviewChanged: false,
              },
            };
          });

          if (page > 1) {
            dataSorted.map((modelo) => {
              const searchIndex = this.posts.findIndex((index) => index._id === modelo._id);
              if (searchIndex !== -1) {
                Vue.set(this.posts, searchIndex, modelo);
              } else {
                this.posts.push(modelo);
              }
            });
          } else {
            Vue.set(this, "posts", dataSorted);
          }

          this.morepage = response.data.hasMore;
          this.total_list = response.data.total;
          this.total = this.total_list;
          this.cpage = this.morepage ? this.cpage : 1;
          _this.elKey++;
        }

        this.loading = false;
      },

      viewModel: function (index) {
        this.$refs.myRecord.modelView({
          action: "open",
          post: this.posts[index],
        });
      },
      updatedModel: async function (model) {
        const index = this.$global.objectIndex(this.posts, "_id", model._id);
        this.posts[index] = model;
        await this.getStatistics();
      },
      newComment: function (comment, postIndex) {
        this.posts[postIndex].comments.unshift(comment);
      },
      dateFormat: (date) => {
        return global.dateLocaleFormat(date);
      },
      toInputDate: (date) => {
        return global.toInputDate(date);
      },
      toInputDateTime: (date) => {
        return global.toInputDateTime(date);
      },
      timeAMPM: (time) => {
        return global.timeAMPM(time);
      },
      yearsDiff: (date1, date2) => {
        return global.yearsDiff(date1, date2);
      },
      linkInclude: function (input) {
        return global.linkInclude(this.$route, input);
      },
      getB64: (data) => {
        return btoa(data);
      },
      cityName: function (city) {
        if (this.locale.words[city]) {
          return this.locale.words[city];
        } else {
          return city;
        }
      },
      citySelect: function (idpost) {
        this.$refs[`city${idpost}`][0].toggle();
      },
      slectModel: (item) => {
        const isCheck = event.srcElement.checked ? true : false;
        if (isCheck === true) {
          document.querySelector(item).classList.add("selected");
        } else {
          document.querySelector(item).classList.remove("selected");
        }
      },
      getContactLink: function (user, contactMode) {
        const label = this.locale.words[contactMode];
        if (contactMode === "phone") {
          return `<a href="tel:${user.phone}">${label}</a>`;
        } else if (contactMode === "whatsapp") {
          return `<a target="_blank" href="https://api.whatsapp.com/send?phone=57${user.phone}&text=">${label}</a>`;
        } else if (contactMode === "email") {
          return `<a target="_blank" href="mailto:${user.email}">${label}</a>`;
        } else {
          return `<a>${label}</a>`;
        }
      },
      testCamera: function (event, index) {
        Vue.set(this.posts[index], "testCamera", event.srcElement.checked.toString());
      },
      interviewMessage: function (thisPost, index) {
        let interview = thisPost.isInterview;
        let interviewDate = thisPost.interviewDate;

        if (interviewDate && interviewDate.includes(".")) {
          interviewDate = interviewDate.split(".")[0];
        }

        let a = new Date();
        let b = new Date(interviewDate);
        let d = b - a;
        let minutes = 160 * 60000;
        let checkbox = `
          <span check>
				    <input name="isInterview" ${interview === "on" ? "checked" : ""} type="checkbox" class=" mycheck ${interview !== "on" ? "hide" : ""}">
			    </span>`;
        let icon = `
            <span check class="houglass-icon">
              <i class="iconic-i">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM96 75V64H288V75c0 19-5.6 37.4-16 53H112c-10.3-15.6-16-34-16-53zm16 309c3.5-5.3 7.6-10.3 12.1-14.9L192 301.3l67.9 67.9c4.6 4.6 8.6 9.6 12.1 14.9H112z"/></svg>
              </i>
            </span>`;

        if (interviewDate && interview !== "on" && d <= -minutes) {
          return `<div message danger small margin-b>No se presentó a la entrevista </div>`;
        } else if (interviewDate && interview !== "on" && d > -minutes) {
          return `<div message info small margin-b>Entrevista programada ${icon} ${checkbox}</div>`;
        } else if (interview === "on") {
          return `<div message success small margin-b>Entrevista realizada ${checkbox}</div>`;
        }
      },
      inputRequire: function (el, error) {
        if (error) {
          this.dataError(error);
        }
      },

      imageToUrl: function (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = function (e) {
            const result = e.target.result;
            resolve(result);
          };
          reader.onerror = function (error) {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
      },

      /**
       * Funcion encargada de la validacion de los archivos que se suben
       * @param {*} el
       * @param {*} index
       */
      validateFile: async function (el, index) {
        const _el = el.srcElement ? el.srcElement : typeof el === "object" ? el : el.srcElement;
        const _val = _el.value ? _el.value.trim() : "";

        const fileExts = {
          photos: new RegExp(`(${this.extensiones.photos.map((ext) => `\\${ext}`).join("|")})$`, "i"),
          video: new RegExp(`(${this.extensiones.video.map((ext) => `\\${ext}`).join("|")})$`, "i"),
        };
        if (_el.files.length && (fileExts.photos.test(_val) || fileExts.video.test(_val))) {
          this.inputRequire(_el);
          const typeFile = fileExts.photos.test(_val) ? "photos" : "videos";
          let fileUrl = null;

          if (typeFile == "photos") fileUrl = await this.imageToUrl(_el.files[0]);
          else fileUrl = URL.createObjectURL(_el.files[0]);

          this.posts[index].multimedia[typeFile].push({
            name: typeFile,
            type: typeFile,
            link: "",
          });

          this.dataSending();
          fileUrl = await this.uploadFileToAWS(this.encodeFileName(_el.files[0]), typeFile, this.posts[index]);
          const lastIndex = this.posts[index].multimedia[typeFile].length - 1;
          Vue.set(this.posts[index].multimedia[typeFile][lastIndex], "link", fileUrl);

          this.dataSending(false);

          this.formChanged = true;
        } else if (_el.files.length && !(fileExts.photos.test(_val) || fileExts.video.test(_val))) {
          this.inputRequire(_el, `Formato de video no admitido, use ${this.extensiones.join(", ")}`);
        } else {
          this.inputRequire(_el, "Video no seleccionado");
        }
        document.querySelector("#inputMultimedia").value = "";
      },
      encodeFileName(file) {
        const originalFileName = file.name;
        const encodedFileName = encodeURIComponent(originalFileName);
        // Renombrar archivo con su antiguo nombre decodificado
        const renamedFile = new File([file], encodedFileName, {
          type: file.type,
        });
        return renamedFile;
      },
      fileToUrl(file) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const result = e.target.result;
          const imgElement = document.getElementById("myImage");
          imgElement.src = result;
        };

        reader.readAsDataURL(file);
      },
      dataScoreEquals: async function (form, options) {
        const _this = this;
        const eqOptions = options.post.ratingsScoreEquals;
        const newOptions = await Promise.all(
          eqOptions.map((option) => {
            let myOption = `<tr>
            <td>
              <input type="radio" id="sps${option.userRating}" name="superSelect" value="
              ${option.userRating}">
            </td>
            <td padding-left>
              <label for="sps${option.userRating}">${this.locale.words[option.userRating]}</label>
            </td>
          </tr>`;
            return myOption;
          })
        );

        const newForm = `<div class="last-ratign-decision">
        <div padding-bottom>
          <span>Por favor, toma una decisión final, </span>
          <span>para <b>${options.post.name}</b></span>
        </div>
        <div><table mauto tleft>${newOptions.join(" ")}</table></div>
      </div>`;

        Swal.fire({
          title: "Votación empate",
          html: newForm,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#2196f3",
          cancelButtonColor: "#bd0909",
          confirmButtonText: "Si, enviar",
          cancelButtonText: "No, cancelar",
          preConfirm: () => {
            const decision = Swal.getPopup().querySelector(".last-ratign-decision input:checked");
            if (!decision) {
              _this.dataError("No has tomado ninguna decisión");
            } else {
              options.append.lastDecision = decision.value.trim();
              _this.dataSend(form, options);
            }
          },
        });
      },
      dataWaitingSelect: function (form, options, infoOptions) {
        const { negativeOption, positiveOption } = infoOptions;
        const _this = this;
        const myOption = `
        <div class="last-ratign-decision">
          <div padding-bottom>
            <span>Decisión final, </span> <span>para <b>${options.post.name}</b></span>
          </div>
          <table mauto tleft>
            <tr>
              <td><input type="radio" id="spsadmitted" name="superSelect" value=${positiveOption.valor}></td>
              <td padding-left><label for="spsadmitted">${positiveOption.nombre}</label></td>
            </tr>
            <tr>
              <td><input type="radio" id="spsnoadmitted" name="superSelect" value=${negativeOption.valor}></td>
              <td padding-left><label for="spsnoadmitted">${negativeOption.nombre}</label></td>
            </tr>
          </table>
        </div>`;

        Swal.fire({
          title: "Decisión",
          html: myOption,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#2196f3",
          cancelButtonColor: "#bd0909",
          confirmButtonText: "Si, enviar",
          cancelButtonText: "No, cancelar",
          preConfirm: () => {
            const decision = Swal.getPopup().querySelector(".last-ratign-decision input:checked");
            if (!decision) {
              _this.dataError("No has tomado ninguna decisión");
            } else {
              options.append.lastDecision = decision.value.trim();
              _this.dataSend(form, options);
            }
          },
        });
      },
      dataNoAssistence: function (form, options, infoOptions) {
        const _this = this;
        const myOption = `
        <div class="last-ratign-decision">
          <div padding-bottom>
            <span>Decisión final, </span> <span>para <b>${options.post.name}</b></span>
          </div>
          <table mauto tleft>
            <tr>
              <td><input type="radio" id="spsnoadmitted" name="superSelect" value='${infoOptions.valor}' checked></td>
              <td padding-left><label for="spsnoadmitted">${infoOptions.nombre}</label></td>
            </tr>
          </table>
        </div>`;

        Swal.fire({
          title: "Decisión",
          html: myOption,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#2196f3",
          cancelButtonColor: "#bd0909",
          confirmButtonText: "Si, enviar",
          cancelButtonText: "No, cancelar",
          preConfirm: () => {
            const decision = Swal.getPopup().querySelector(".last-ratign-decision input:checked");
            if (!decision) {
              _this.dataError("No has tomado ninguna decisión");
            } else {
              options.append.lastDecision = decision.value.trim();
              _this.dataSend(form, options);
            }
          },
        });
      },
      dataNoSelect: function (form, options) {
        const _this = this;
        const commentbox = document.querySelector(`#${form} [name="commentbox"]`);
        const myOption = `
        <div class="last-ratign-decision">
          <div padding-bottom>
            <p>El registro de <b>${options.post.name}</b> pasará al estado aprobado</p>
          </div>
          <table mauto tleft>
            <tr>
              <td><input type="radio" id="spspending" name="superSelect" value="approved" checked></td>
              <td padding-left><label for="spspending">Aprobar</label></td>
            </tr>
          </table>
        </div>`;

        Swal.fire({
          title: "Retomar Proceso",
          html: myOption,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#2196f3",
          cancelButtonColor: "#bd0909",
          confirmButtonText: "Si, enviar",
          cancelButtonText: "No, cancelar",
          preConfirm: () => {
            const decision = Swal.getPopup().querySelector(".last-ratign-decision input:checked");
            if (!decision) {
              _this.dataError("No has tomado ninguna decisión");
            } else {
              options.append.lastDecision = decision.value.trim();
              _this.dataSend(form, options);
            }
          },
        });
      },
      dataPending: function (form, options) {
        const _this = this;
        const myOption = `
        <div class="last-ratign-decision">
          <div padding-bottom>
            <p>El registro de <b>${options.post.name}</b> pasará al estado pendientes</p>
          </div>
          <table mauto tleft>
            <tr>
              <td><input type="radio" id="spspending" name="superSelect" value="pending" checked></td>
              <td padding-left><label for="spspending">Pendiente</label></td>
            </tr>
          </table>
        </div>`;

        Swal.fire({
          title: "Proceso Pendiente",
          html: myOption,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#2196f3",
          cancelButtonColor: "#bd0909",
          confirmButtonText: "Si, enviar",
          cancelButtonText: "No, cancelar",
          preConfirm: () => {
            const decision = Swal.getPopup().querySelector(".last-ratign-decision input:checked");
            if (!decision) {
              _this.dataError("No has tomado ninguna decisión");
            } else {
              options.append.lastDecision = decision.value.trim();
              _this.dataSend(form, options);
            }
          },
        });
      },
      dataDeleteConfirm: function (form, options) {
        const _this = this;
        const deserterOption = `<tr>
              <td><input type="radio" id="spsnoadmitted" name="superSelect" value="deserter"></td>
              <td padding-left><label for="spsnoadmitted">Desertora</label></td>
            </tr>`;
        const myOption = `
        <div class="last-contract-decision">
          <div padding-bottom>
            <span>Eliminar los datos de </span> <span> <b>${options.post.name}</b> para que pueda
            volverse a registrar e iniciar nuevamente el proceso</span>
          </div>
          <table mauto tleft>
            <tr>
              <td><input type="radio" id="spsadmitted" name="superSelect" value="delete"></td>
              <td padding-left><label for="spsadmitted">Eliminar todo</label></td>
            </tr>
            ${_this.filter === "admitted" ? deserterOption : ""}
          </table>
        </div>`;

        Swal.fire({
          title: "Actualizar",
          html: myOption,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#2196f3",
          cancelButtonColor: "#bd0909",
          confirmButtonText: "Si, enviar",
          cancelButtonText: "No, cancelar",
          preConfirm: () => {
            const decision = Swal.getPopup().querySelector(".last-contract-decision input:checked");
            if (!decision) {
              _this.dataError("No has seleccionado una acción");
            } else {
              options.append.lastDecision = decision.value.trim();
              _this.dataSend(form, options);
            }
          },
        });
      },

      dataAdmittedConfirm: function (form, options) {
        const _this = this;
        const isComplete = document.querySelector(`#${form} [name="endProcess"]`);
        const deserterOption = `<tr>
              <td><input type="radio" id="spsnoadmitted" name="superSelect" value="deserter"></td>
              <td padding-left><label for="spsnoadmitted">Desertora</label></td>
            </tr>`;
        const myOption = `
        <div class="last-contract-decision">
          <div padding-bottom>
            <span>Decisión final, </span> <span>para <b>${options.post.name}</b></span>
          </div>
          <table mauto tleft>
            <tr>
              <td><input type="radio" id="spsadmitted" name="superSelect" value="hired"></td>
              <td padding-left><label for="spsadmitted">Contratada</label></td>
            </tr>
            ${_this.filter === "admitted" ? deserterOption : ""}
          </table>
        </div>`;
        if (!isComplete.checked) {
          _this.dataSubmitConfirm(form, options);
        } else {
          Swal.fire({
            title: "Contrato",
            html: myOption,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#2196f3",
            cancelButtonColor: "#bd0909",
            confirmButtonText: "Si, enviar",
            cancelButtonText: "No, cancelar",
            preConfirm: () => {
              const decision = Swal.getPopup().querySelector(".last-contract-decision input:checked");
              if (!decision) {
                _this.dataError("No has tomado ninguna decisión");
              } else {
                options.append.lastDecision = decision.value.trim();
                _this.dataSend(form, options);
              }
            },
          });
        }
      },
      editType: async function (post) {
        const index = this.posts.findIndex((item) => item._id === post._id);
        if (index !== -1) {
          this.posts.splice(index, 1, post);
        }
      },
      onContract: function () {
        const decision = document.querySelector(".hired-contract-decision input:checked");
        const { form, options } = this.dataHiredModal;
        this.isShowModalHired = false;
        if (!decision) {
          this.dataError("No has tomado ninguna decisión");
        } else {
          options.append.lastDecision = decision.value.trim();
          const reason = document.querySelector(".hired-contract-decision select");
          options.append.typeDecision = reason.value;
          this.dataSend(form, options);
        }
        this.dataHiredModal = {};
      },
      dataHiredConfirm: function (form, options) {
        const isComplete = document.querySelector(`#${form} [name="endProcess"]`);
        if (!isComplete.checked) {
          this.dataSubmitConfirm(form, options);
        } else {
          this.isShowModalHired = true;
          this.dataHiredModal = { form, options };
        }
      },
      dataSubmitConfirm: function (form, options) {
        const _this = this;
        let extratxt = "";
        if (options && options?.append?.action === "super-select" && options?.post?.ratingsScoreEquals) {
          let ratingEq = options.post.ratingsScoreEquals.length;
          if (ratingEq > 1) {
            return _this.dataScoreEquals(form, options);
          } else {
            const lastDecision = options.post.ratingsScoreEquals[0].userRating;
            options.append.lastDecision = lastDecision.trim();
            extratxt = `, su calificacion final es <b dcolor>${this.locale.words[lastDecision]}</b>`;
          }
        }
        const dataForm = new FormData(document.querySelector(`#${form}`));
        const interviewDate = new Date(dataForm.get("interviewDate")).getTime();

        if (options.post.adminStatus == "noassistence") {
          if (interviewDate < new Date().getTime()) {
            _this.dataError("La fecha de la entrevista es incorrecta");
            return;
          }
        }

        Swal.fire({
          title: "Esta seguro ?",
          html: "El modelo será actualizado" + extratxt,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#2196f3",
          cancelButtonColor: "#bd0909",
          confirmButtonText: "Si, enviar",
          cancelButtonText: "No, cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            _this.dataSend(form, options);
          }
        });
      },
      uploadFileToAWS: async function (file, typeFile, model) {
        try {
          this.formChanged = false;

          model.history.push({
            date: new Date(),
            user: this.userName,
            action: "Agreg&#243; un archivo.",
          });
          const { _id, multimedia, testCamera, history } = model;
          const _host = `admin/models/uploadFile`;
          const _data = new FormData();
          _data.append("file", file);
          _data.append("typeFile", typeFile);
          _data.append("model", JSON.stringify({ _id, multimedia, testCamera, history }));
          const { data } = await this.$axios.post(_host, _data);
          return data.Location;
        } catch (error) {
          console.log(error);
        }
      },

      dataSend: async function (formId, options) {
        const _this = this;
        const _host = `admin/models/reviewAction`;
        const _data = new FormData(document.getElementById(formId));
        _data.append("updated", new Date());
        const commentbox = document.querySelector(`#${formId} .textarea-rich-box`);

        if (commentbox) {
          const comment = commentbox.innerHTML;
          _data.append("commentbox", encodeURIComponent(comment.trim()));
        }
        if (options && typeof options.append === "object") {
          Object.keys(options.append).map((i) => {
            _data.append(i, options.append[i]);
          });
        }
        options.post.multimedia.videos.filter((video) => video.hasOwnProperty("new"));
        _data.set("multimedia", JSON.stringify({ multimedia: options.post.multimedia }));
        _this.dataSending();

        await this.$axios
          .post(_host, _data)
          .then(function (response) {
            if (response.data.state) {
              if (_this.filter !== response.data.adminStatus) {
                _this.getPosts(_this.cpage);
              }
              _this.updateReviewPost(response.data);
              _this.dataSuccess(response.data.message);

              _this.$root.$emit("updateStatics");
            } else {
              _this.dataError(response.data.message);
            }
          })
          .catch(function (error) {
            const message = error?.response?.data?.error?.message || error?.message;
            _this.dataError(message);
          });
      },
      dataSending: function (keepOpen = true) {
        Swal.fire({
          title: "Enviando",
          text: "Sus datos están siendo enviados",
          timerProgressBar: true,
          allowOutsideClick: false,
          didOpen: () => {
            if (keepOpen) {
              Swal.showLoading();
            } else {
              Swal.close();
            }
          },
        });
      },
      dataError: function (message) {
        Swal.fire({
          title: "Error",
          html: message,
          icon: "error",
          confirmButtonText: "Revisar",
          timerProgressBar: true,
          confirmButtonColor: "#bd0909",
        });
      },
      dataSuccess: function (message) {
        Swal.close();
        Swal.fire({
          title: "Actualizado",
          text: message ? message : "El modelo ha sido actualizado",
          icon: "success",
          confirmButtonText: "Aceptar",
          timerProgressBar: false,
          confirmButtonColor: "#bd0909",
        });
      },
      updateReviewPost: function ({ updated }) {
        const index = this.posts.findIndex((item) => item._id === updated._id);
        if (index !== -1) {
          updated.multimedia = updated?.multimedia ?? { photos: [], videos: [] };

          if (!updated.multimedia.videos.length) {
            updated.multimedia.videos = [];
          }

          if (!updated.multimedia.photos.length) {
            updated.multimedia.photos = [];
          }

          this.posts[index] = updated;
        }
      },
      getStatistics: async function () {
        this.$root.$emit("updateStatics");
      },
      goInterview: async function (model) {
        let route = this.$router.resolve({
          name: "interview",
          query: { email: model.email, sesion: this.$store.state.userProfile.SSID },
        });
        window.open(route.href, "_blank");
      },
      onLinkToChange: async function (evt, model, flag, index) {
        const nameHtmlElement = evt.target.name;
        if ((nameHtmlElement.trim() && !this.posts[index].isInterview) || !nameHtmlElement.trim()) {
          await this.changeAvailableInterview(flag, model.email);
        }
        this.handleCheckedInterview(index, flag);
      },
      changeAvailableInterview: async function (checked, email) {
        const _host = `${process.env.VUE_APP_API_HOST}admin/models/updatemodel`;
        await api.post(_host, {
          email: email,
          availableInterview: checked,
        });
      },
      async getModelsfilter() {
        const host = `${process.env.VUE_APP_API_HOST}admin/allModels`;
        const { data } = await this.$api.get(`${host}`);
        this.$store.state.allModels = data || [];
      },
      /**
       * Funcion encargada de finalizar el proceso de la entrevista de las modelos
       * @param {String} id id de la modelo
       */
      interviewFinished: async function (id) {
        const indexModelo = this.posts.findIndex((modelo) => modelo._id == id);
        if (indexModelo >= 0) {
          Vue.set(this.posts[indexModelo], "isInterview", "on");
          Vue.set(this.posts[indexModelo], "isSelected", "true");
          Vue.set(this.posts[indexModelo], "availableInterview", false);
          // Refresca el componente para que se reflejen los valores
          this.resetKey++;
        }
      },
    },
    mounted: async function () {
      if (!this.socket) {
        this.initializeSocketBackend();
      }
      const { user, role } = await this.$store.state.userProfile;
      this.roleUser = role;
      this.userName = user;
      this.$root.$on("refreshlang", async (data) => {
        await this.getPosts();
      });
      this.$root.$on("newStatistics", async (data) => {
        this.statistics = data.statistics;
        this.total = data.total;
      });
      if (this.filter === "approved" || this.filter === "pending") {
        if (this.$route.query.interview === "today") {
          this.filterInfo("$total Entrevistas de hoy");
        } else if (this.$route.query.interview) {
          this.filterInfo(`$total Entrevistas del ${this.$global.dateLocaleFormat(this.$route.query.interview)}`);
        }
      }
      await this.getModelsfilter();
      await this.getPosts();
    },
    computed: {
      initData: async function () {
        this.filter = this.$route.query.filter ? this.$route.query.filter : "";
        this.mode = this.$route.query.mode ? this.$route.query.mode : "";
        this.getdataLang();
        await this.getStatistics();
        this.countries = await globals.getCountriesInfo();
        // Como el filtro de de entrevistas solo esta para estadisticas y aprovados, el filtro solo se debe aplicar alla
        if (this.$route.query.hasOwnProperty("interview") && !["all", "approved"].includes(this.$route.query["filter"])) {
          const currentRoute = this.$route;
          currentRoute.query["interview"] = "";
          this.$router.push({ query: currentRoute.query });
          await this.getStatistics();
        }
      },
      initList: async function () {
        this.filter = this.$route.query.filter ? this.$route.query.filter : "";
        this.mode = this.$route.query.mode ? this.$route.query.mode : "";
        this.getdataLang();
        await this.getStatistics();
        await this.getPosts();
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .phone-info {
    display: flex;
    align-items: center;
    width: 200px;
    gap: 5px;
    img {
      max-width: 25px;
    }
  }
  .loader {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    border: 10px solid #f9f8fd;
    border-top-color: $primary_color;
    animation: spin013151 1s linear infinite;
    border-radius: 100%;
  }
  @keyframes spin013151 {
    to {
      transform: rotate(360deg);
    }
  }
  .sendIcon {
    margin: 10px 0;
    max-width: 192px;
    padding: 1px;
    &__icon {
      color: $primary_color;
      margin-left: 5px;
    }
    &:hover {
      cursor: pointer;
    }
    &__text:hover {
      border-bottom: solid gray 1px;
    }
    &__span {
      display: flex;
    }
  }
  .hiddenToogle {
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }
  .history__container {
    max-height: 400px;
    overflow: auto;
    padding: 0 10px;
    .history__section {
      .date__history {
        position: sticky;
        top: 0;
        color: #fff;
        font-weight: bold;
        padding: 5px;
        background: rgba($color: $primary_color, $alpha: 0.7);
      }

      .history__item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 10px 0;
        text-align: left;

        h1 {
          font-size: 20px;
          margin-bottom: 5px;
        }
        p {
          font-size: 14px;
        }
        font-weight: bold;
        p.danger {
          color: red;
        }
        p.success {
          color: green;
        }
      }
    }
  }

  .interviewRow {
    display: flex;
    .toggle {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      &-label {
        margin: 0 $mpadding/2 0 0;
      }
    }
    .toggle-label {
      white-space: nowrap;
    }
    .interviewRowField {
      display: flex;
      justify-content: space-between;
      gap: $mpadding;
      flex: 1;
      &-left,
      &-right {
        display: flex;
        flex: 1;
      }
      &-right {
        justify-content: flex-end;
      }
    }
  }

  .rowLinkTo {
    align-items: center;
    .check {
      margin: 0;
      @include Flex(row);
    }
    .linkTo {
      margin: 0 5px;
      font-size: 16px;
      color: $primary_color;
      cursor: pointer;
    }
    .toggleLinkTo {
      @include Flex(row);
      font-size: 80%;
    }
    @media screen and(min-width: $desktop_width) {
      .check {
        grid-area: check;
      }
      .labeInterview {
        grid-area: label;
      }
      .linkTo {
        grid-area: link;
      }
      .toggleLinkTo {
        grid-area: toggle;
        justify-self: flex-start;
        margin: 7.5px 0;
      }
    }
  }

  .section-models-posts {
    &-header {
      background: $white;
      &-content {
        display: block;
        flex: 1;
        width: 100%;
        justify-content: space-around;
        border-bottom: solid 1px $alto;
        white-space: nowrap;
        overflow-x: auto;
        .wrp-btn {
          text-align: center;
          display: inline-block;
          min-width: 33.33%;
          color: $primary-color;
          > a {
            display: block;
            padding: $mpadding;
            background: $white;
          }
          &:hover {
            color: $primary-color;
            font-weight: bold;
          }
          &-ctx-menu {
            position: absolute;
            border: solid 1px $alto;
            box-shadow: $dshadow;
            background: $white;
            min-width: 33.33%;
            right: 0;
            left: 0;
            display: none;
            &-button {
              display: flex;
              align-items: center;
              padding: $mpadding;
              cursor: pointer;
              .iconic + *:not(:empty) {
                margin-left: $mpadding/2;
              }
              &:hover {
                background: $white_smoke;
              }
              &.--inputs {
                padding: 0 0 0 $mpadding;
                input:not([type="checkbox"]):not([type="radio"]) {
                  padding: $mpadding $mpadding $mpadding 0;
                  font-size: 100%;
                  font-family: $first_font;
                  border: none;
                  background: transparent;
                }
              }
            }
          }
          &:hover {
            .router-link-exact-active + .wrp-btn-ctx-menu {
              display: block;
              position: absolute;
              z-index: 10;
            }
          }
        }
      }
    }
    &-main {
      display: inline-block;
      width: 100%;
      .list-item {
        display: flex;
        padding: $mpadding 0;
        line-height: 24px;
        &:hover {
          box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 #3c40434d, 0 1px 3px 1px #3c404326;
          z-index: 2;
          background: $white;
        }
        &:not(:last-child) {
          border-bottom: solid 1px $alto;
        }
        .model-selected {
          display: flex;
          flex-direction: column;
          .btn-history {
            margin-top: 10px;
            background: transparent;
            border: none;
            cursor: pointer;
            .iconic {
              font-size: 18px;
              transition: 0.2s ease;
              &:hover {
                color: $primary_color;
              }
            }
          }
        }
        &-content {
          display: flex;
          width: 100%;
          > div {
            &:first-child {
              margin-left: 10px;
              padding-top: 3px;
            }
            &:not(:first-child) {
              margin: 0 10px;
              flex: 1;
            }
          }
        }
        .model-info {
          &-data {
            .info {
              margin: 0 5px;
              .date {
                font-size: 85%;
                .view-reg {
                  margin-left: 5px;
                  cursor: pointer;
                  border-bottom: solid 1px;
                }
              }
              .header-info {
                display: flex;
                align-items: center;
                gap: 10px;
                .city-info .row {
                  display: flex;
                  align-items: center;
                  .city-label {
                    margin-right: 5px;
                    background: #f09d00;
                    border-radius: 20px;
                    padding: 5px 10px;
                    color: rgb(61, 61, 61);
                  }
                }
              }
            }
            .model-ratings {
              display: block;
              position: relative;
              &-history,
              &-score {
                display: inline-block;
                background: $white;
                padding: $mpadding/2;
                border-radius: $mradius/2;
                border: solid 1px $emperor;
                margin: $mpadding/2 $mpadding/2 0 0;
                .row {
                  vertical-align: text-top;
                  > td:not(:first-child) {
                    padding-left: $mpadding/2;
                  }
                  .rating {
                    color: $primary_color;
                  }
                }
              }
            }
            .model-rate-myrating {
              margin-top: $mpadding/2;
              [role="select-radio"] {
                display: flex;
                [role="option"] {
                  margin-right: $mpadding;
                  label {
                    padding-left: $mpadding/2;
                  }
                }
              }
            }
            .model-assessment {
              margin-top: $mpadding/2;
            }
          }
          textarea,
          select,
          input[type="text"],
          input[type*="date"] {
            width: 100%;
            padding: $mpadding/2;
            border-radius: $mradius/2;
            border: solid 1px $alto;
            font-size: 100%;
            font-family: $first_font;
            margin-top: 5px;
            &.m-bottom {
              margin-bottom: 5px;
            }
          }
          .houglass-icon {
            width: 26px;
            height: 26px;
            text-align: center;
            padding: 2px 2px;
            border-radius: 50%;
            border: 1px solid gray;
            background-color: white;
          }
          textarea {
            min-height: 70px;
          }
        }
        .btn-action {
          cursor: pointer;
        }
        .model-options {
          .btn-action {
            display: inline-block;
            margin: $mpadding/2 0 0 0;
            .link-i {
              transition: 0.3s ease-in-out;
              &:hover {
                font-weight: 900;
              }
            }
          }
        }
        .info-message,
        .test-info {
          margin-top: $mpadding;
          .row:not(.hidden) {
            display: flex;
            margin-bottom: 5px;
            .check {
              margin-right: 5px;
              width: 24px;
            }
            .container-new-interview {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              width: 100%;
            }
            .btn-action {
              display: block;
              border-radius: 5px;
            }
          }
          .newInterview {
            margin: 10px 0;
          }
          .box-reprogramar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .iconic {
              font-size: 20px;
              color: $primary_color;
            }
          }
          .row-comments {
            width: 100%;
            max-height: 100px;
            overflow-y: auto;
            margin: 5px 0 5px 0;
          }
          .end-process {
            margin: $mpadding/2 0 0 0;
            user-select: none;
          }
        }
        .pictures {
          flex: 1;
          overflow: hidden;
          overflow-x: auto;
          .uploadFile {
            display: grid;
            place-items: center;
            position: relative;
            height: 100%;
            width: 100%;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.03);
          }
          &-content {
            max-width: 600px;
            display: flex;
            margin-top: 5px;
            flex-wrap: wrap;
            .picture {
              position: relative;
              overflow: hidden;
              width: 70px;
              height: 70px;
              margin: 5px;
              .deleteFile {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 5;
                cursor: pointer;
                border: none;
                padding: 2px;
                background: $primary_color;
                color: #fff;
                .iconic {
                  font-size: 16px;
                }
              }
              &:hover {
                background-color: rgba(0, 0, 0, 0.03);
                box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.452);
              }
              img {
                width: 100%;
                height: 100%;
              }
              &.video {
                &::before {
                  content: "";
                  width: 100%;
                  height: 100%;
                  background: rgba(0, 0, 0, 0.5);
                }
                &::after {
                  font-family: FontAwesome;
                  content: "\f144";
                  font-size: 220%;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  color: $white;
                }
                &::before,
                &::after {
                  position: absolute;
                  pointer-events: none;
                }
                &:hover::after {
                  color: $primary_color;
                }
                .wrap-link {
                  display: block;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
          &__video {
            width: 70px;
            height: 70px;
            object-fit: cover;
            margin-top: $space-6;
            cursor: pointer;
          }
        }
        &.younger {
          background: $primary_color_saturate;
          .model-age {
            color: $primary_color;
          }
        }
        &.selected {
          border-right: solid 4px $primary_color;
        }
      }
    }
    &__logo {
      width: 65px;
      margin-right: $space-6;
    }
    @media (min-width: $desktop_width) {
      &-header {
        top: 0;
        &-content {
          display: flex;
          flex: 1;
          .wrp-btn {
            width: 100%;
            min-width: auto;
            text-align: center;
            > a {
              display: block;
              padding: $mpadding;
              background: $white;
            }
            &-ctx-menu {
              min-width: 25%;
              left: auto;
              right: auto;
              &.coord {
                min-width: 50%;
              }
            }
          }
        }
      }
      &-main {
        .list-item {
          .model-info {
            display: flex;
            justify-content: space-between;
            &-data {
              width: 50%;
            }
            .info-message,
            .test-info {
              margin-top: 0;
              padding: 0 $mpadding;
              width: 40%;
              .rowLinkTo {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                row-gap: $mpadding/3;
              }
            }
            .test-info {
              width: 23%;
            }
            .model-ratings {
              display: flex;
            }
          }
        }
      }
    }
    .social {
      display: flex;
      gap: 10px;
      .social-btn {
        font-size: 20px;
        width: 35px;
        height: 35px;
      }
    }
  }
</style>
